export const ADD_ITEM_CART = 'ADD_ITEM_CART';
export const REMOVE_ITEM_CART = 'REMOVE_ITEM_CART';
export const ADD_ADRESS_CART = 'ADD_ADRESS_CART';
export const REMOVE_ADRESS_CART = 'REMOVE_ADRESS_CART';
export const ADD_PAYMENTM_CART = 'ADD_PAYMENTM_CART';
export const REMOVE_PAYMENTM_CART = 'REMOVE_PAYMENTM_CART';
export const ADD_CART_STORAGE = 'ADD_CART_STORAGE';
export const DELETE_CART = 'DELETE_CART';
export const FINISHED_CART = 'FINISHED_CART';
export const CHANGE_QUANTITY_CART = 'CHANGE_QUANTITY_CART';
export const MESSAGES_CART = 'MESSAGES_CART';
export const SET_TOTALCART = 'SET_TOTALCART';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
