const VisaIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		width="1.7em"
		height="1.7em"
		viewBox="0 0 1000 1000"
		{...props}
	>
		<path
			d="M40 186c-22.087 0-40 17.908-40 40v548c0 22.091 17.922 40 40 40h920c22.087 0 40-17.908 40-40V226c0-22.091-17.922-40-40-40H40zm557.25 187.156c24.338 0 43.843 5.376 56.281 10.375l-8.5 53.469-5.625-2.844c-11.587-4.999-26.47-9.81-47-9.469-24.576 0-35.937 10.963-35.937 21.22-.143 11.557 13.275 19.179 35.218 30.593 36.217 17.61 52.956 38.962 52.72 67.031-.488 51.217-43.324 84.313-109.313 84.313-28.153-.31-55.276-6.27-69.938-13.157l8.813-55.25 8.094 3.938c20.617 9.212 33.967 12.938 59.093 12.938 18.043 0 37.409-7.555 37.563-24.094.117-10.8-8.095-18.5-32.532-30.594-23.812-11.804-55.38-31.577-55.03-67.031.372-47.961 44.063-81.438 106.093-81.438zM88.437 381h101.344c13.647.516 24.665 4.91 28.469 19.719l21.844 112.75c.003.01-.004.051 0 .062l6.562 33.782L308.187 381h66.563l-98.938 243.438-66.5.062-52.937-196.438c31.498 16.687 58.314 35.994 73.844 62.563-4.004-8.407-9.28-17.897-16.031-27.25-7.862-10.892-24.841-24.955-31.938-30.938-24.73-20.847-58.315-37.684-94.594-46.593l.781-4.844zm312.782.281h65.094l-40.72 243.063H360.5l40.719-243.063zm367.656 0h49.313l51.625 243.063h-59.188s-5.872-27.924-7.781-36.438c-9.303 0-74.367-.093-81.688-.093-2.477 6.58-13.437 36.53-13.437 36.53H640.75l94.719-222.874c6.706-15.838 18.137-20.188 33.406-20.188zm4.844 65.375c-3.203 9.041-8.78 23.641-8.407 23 0 0-20.037 53.44-25.28 67.313l52.655-.032A191955.64 191955.64 0 0 0 778 467.408l-4.281-20.75z"
			fill="#606060"
		/>
	</svg>
);

export default VisaIcon;
