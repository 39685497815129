export const SliderData = [
	{
		image:
			'https://res.cloudinary.com/dyqe8zbsd/image/upload/v1643343070/slider/33_rj3m83.jpg',
		alt: 'Saco',
	},
	{
		image:
			'https://res.cloudinary.com/dyqe8zbsd/image/upload/v1643343070/slider/11_qfrvrm.jpg',
		alt: 'Saco',
	},
	{
		image:
			'https://res.cloudinary.com/dyqe8zbsd/image/upload/v1643343070/slider/22_wfe7yo.jpg',
		alt: 'Camisas lisas',
	},
	// {
	// 	image:
	// 		'https://res.cloudinary.com/dyqe8zbsd/image/upload/v1640649920/slider/WhatsApp_Image_2021-10-15_at_12.25.52_vwznue.jpg',
	// 	alt: 'Saco',
	// },
	// {
	// 	image:
	// 		'https://res.cloudinary.com/dyqe8zbsd/image/upload/v1640649907/slider/WhatsApp_Image_2021-11-25_at_09.52.28_qelnfu.jpg',
	// 	alt: 'Saco',
	// },
	// {
	// 	image:
	// 		'https://res.cloudinary.com/dyqe8zbsd/image/upload/v1640649915/slider/WhatsApp_Image_2021-11-04_at_08.55.00_qvzkej.jpg',
	// 	alt: 'Saco',
	// },
];
